var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        [
          !_vm.isDetail
            ? _c(
                "div",
                { staticClass: "m-3" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "plus" },
                      on: { click: _vm.addRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-table",
            {
              attrs: {
                "data-source": _vm.dtSource,
                scroll: { x: "calc(2800px + 50%)", y: 620 },
                pagination: {
                  showTotal: function(total) {
                    return _vm.$t("lbl_total_items", { total: total })
                  }
                },
                "row-key": function(r, i) {
                  return i
                },
                loading: _vm.loading
              }
            },
            [
              _c(
                "a-table-column",
                { key: "no", attrs: { "data-index": "no", width: "80px" } },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "documentReference",
                  attrs: { "data-index": "documentReference" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          record.fromDO
                            ? _c("span", [
                                _vm._v(_vm._s(record.documentReference || "-"))
                              ])
                            : _c("a-input", {
                                attrs: {
                                  "allow-clear": "",
                                  placeholder: _vm.$t("lbl_type_here"),
                                  disabled: _vm.isUnpaid
                                },
                                on: { change: _vm.commitStore },
                                model: {
                                  value: record.documentReference,
                                  callback: function($$v) {
                                    _vm.$set(record, "documentReference", $$v)
                                  },
                                  expression: "record.documentReference"
                                }
                              })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_document_reference")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "productCode",
                  attrs: { "data-index": "productCode" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          record.fromDO
                            ? _c("span", [
                                _vm._v(_vm._s(record.productCode || "-"))
                              ])
                            : _c("CSelectMasterProductCode", {
                                attrs: {
                                  "prop-search-by": "type~SERVICE",
                                  "is-disabled": _vm.isUnpaid
                                },
                                on: {
                                  "on-select": function(ref) {
                                    var meta = ref.meta

                                    return _vm.onSelectProduct({
                                      meta: meta,
                                      record: record,
                                      col: "productName"
                                    })
                                  }
                                },
                                model: {
                                  value: record.productCode,
                                  callback: function($$v) {
                                    _vm.$set(record, "productCode", $$v)
                                  },
                                  expression: "record.productCode"
                                }
                              })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "is-required",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_product_code")))]
                  )
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "productName",
                  attrs: { "data-index": "productName" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          record.fromDO
                            ? _c("span", [
                                _vm._v(_vm._s(record.productName || "-"))
                              ])
                            : _c("CSelectMasterProductName", {
                                attrs: {
                                  "is-disabled": _vm.isUnpaid,
                                  "prop-search-by": "type~SERVICE"
                                },
                                on: {
                                  "on-select": function(ref) {
                                    var meta = ref.meta

                                    return _vm.onSelectProduct({
                                      meta: meta,
                                      record: record,
                                      col: "productCode"
                                    })
                                  }
                                },
                                model: {
                                  value: record.productName,
                                  callback: function($$v) {
                                    _vm.$set(record, "productName", $$v)
                                  },
                                  expression: "record.productName"
                                }
                              })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "is-required",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_product_name")))]
                  )
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "batchNumber",
                  attrs: { "data-index": "batchNumber" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(record.batchNumber || "-"))
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "brand",
                  attrs: { "data-index": "brand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [_vm._v(_vm._s(record.brand || "-"))])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_brand")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "qtyDO",
                  attrs: { "data-index": "qtyDO" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("toDecimalQty")(record.qtyDO)))
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_qty_do")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "qty",
                  attrs: { "data-index": "qty" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("a-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "read-only": record.fromDO,
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              disabled: _vm.isUnpaid,
                              precision: 2,
                              placeholder: _vm.$t("lbl_type_here")
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeQty(record)
                              }
                            },
                            model: {
                              value: record.qty,
                              callback: function($$v) {
                                _vm.$set(record, "qty", _vm._n($$v))
                              },
                              expression: "record.qty"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "is-required",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_qty")))]
                  )
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "uomId",
                  attrs: { "data-index": "uomId", width: "90px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [_c("span", [_vm._v(_vm._s(record.uom || "-"))])]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_uom")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "price",
                  attrs: { "data-index": "price" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("a-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: 2,
                              placeholder: _vm.$t("lbl_type_here"),
                              disabled: _vm.isUnpaid
                            },
                            on: { change: _vm.onChangePrice },
                            model: {
                              value: record.price,
                              callback: function($$v) {
                                _vm.$set(record, "price", _vm._n($$v))
                              },
                              expression: "record.price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "is-required",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_price")))]
                  )
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "baseAmount",
                  attrs: { "data-index": "baseAmount" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("currency")(record.baseAmount))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_base_amount")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "taxId",
                  attrs: { "data-index": "taxId" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("InvoiceSelectTaxCode", {
                            attrs: {
                              disabled:
                                _vm.form.taxType === _vm.TAX_CALCULATION.NONE ||
                                _vm.isUnpaid
                            },
                            on: {
                              "on-select": function(ref) {
                                var meta = ref.meta

                                return _vm.onChangeTaxCode(meta, record)
                              }
                            },
                            model: {
                              value: record.taxId,
                              callback: function($$v) {
                                _vm.$set(record, "taxId", $$v)
                              },
                              expression: "record.taxId"
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "is-required",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_tax_code")))]
                  )
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "taxAmount",
                  attrs: { "data-index": "taxAmount" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("currency")(record.taxAmount)))
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_tax_amount")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "subTotal",
                  attrs: { "data-index": "subTotal" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("currency")(record.subTotal)))
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_sub_total")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "description",
                  attrs: { "data-index": "description" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          record.fromDO
                            ? _c("span", [
                                _vm._v(_vm._s(record.description || "-"))
                              ])
                            : _c("a-textarea", {
                                attrs: {
                                  disabled: _vm.isUnpaid,
                                  placeholder: _vm.$t("lbl_type_here"),
                                  "auto-size": { minRows: 1, maxRows: 4 }
                                },
                                on: { change: _vm.onChangeDescription },
                                model: {
                                  value: record.description,
                                  callback: function($$v) {
                                    _vm.$set(record, "description", $$v)
                                  },
                                  expression: "record.description"
                                }
                              })
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_description")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "alias",
                  attrs: { "data-index": "alias" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [_vm._v(_vm._s(record.alias || "-"))])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_alias")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "action",
                  attrs: { width: "150px", fixed: "right", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.viewMore(record)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_more")))]
                              ),
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isDetail,
                                    expression: "!isDetail"
                                  }
                                ],
                                attrs: { type: "vertical" }
                              }),
                              _c("a-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isDetail,
                                    expression: "!isDetail"
                                  }
                                ],
                                staticClass: "icon-delete",
                                attrs: { type: "delete", theme: "filled" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteRow(record)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_action")))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("InvoiceModalDetail", {
        model: {
          value: _vm.modal.show,
          callback: function($$v) {
            _vm.$set(_vm.modal, "show", $$v)
          },
          expression: "modal.show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }