

















































































































































































































































































import Vue from "vue";
import { formatterNumber, reverseFormatNumber, formatCurrency } from "@/validator/globalvalidator";
import { initTabDetailsSource } from "@/store/resource/invoice.resource";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { productService } from "@/services-v2/product.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { TAX_CALCULATION, TAX_TYPE } from "@/models/enums/tax.enum";
import { Decimal } from "decimal.js-light";
import { InvoiceDetailSource } from "@/store/interface/store-invoice.interface";
import { AR_INVOICE_STATUS } from "@/models/enums/invoice.enum";
export default Vue.extend({
  name: "InvoiceTabDetails",
  components: {
    InvoiceSelectTaxCode: () => import(/*webpackPrefetch: true */"./InvoiceSelectTaxCode.vue"),
    InvoiceModalDetail: () => import(/*webpackPrefetch: true */"./InvoiceModalDetail.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      TAX_CALCULATION,
      TAX_TYPE,
      dtSource: [] as InvoiceDetailSource[],
      loading: false,
      modal: {
        show: false,
        record: {}
      },
      deletedRow: [] as InvoiceDetailSource[],
    };
  },
  computed: {
    ...mapState({
      tabDetailSource: (st: any) => st.invoiceStore.tabDetailSource,
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
      form: (st: any) => st.invoiceStore.form,
    }),
    ...mapGetters({
      getPreferenceValue: "preferenceStore/GET_PREFERENCE_VALUE",
    }),
    isDetail(): boolean {
      return !!this.detailInvoice.status;
    },
    isUnpaid(): boolean {
      return this.detailInvoice.status === AR_INVOICE_STATUS.UNPAID;
    },
    isWaitForApproval(): boolean {
      return this.detailInvoice.status === AR_INVOICE_STATUS.NEED_APPROVAL;
    },
    isTaxNone(): boolean {
      return this.form.taxType === TAX_CALCULATION.NONE;
    },
    isTaxExclusive(): boolean {
      return this.form.taxType === TAX_CALCULATION.EXCLUSIVE;
    },
    isTaxInclusive(): boolean {
      return this.form.taxType === TAX_CALCULATION.INCLUSIVE;
    },
  },
  watch: {
    tabDetailSource(newVal): void {
      if (newVal) this.dtSource = newVal;
    },
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    ...mapMutations({
      setTabDetailSource: "invoiceStore/SET_TAB_DETAIL_SOURCE",
      setTabDetailRow: "invoiceStore/SET_TAB_DETAIL_ROW",
      setTabDetailRowByKey: "invoiceStore/SET_TAB_DETAIL_ROW_BY_KEY",
      setForm: "invoiceStore/SET_FORM",
    }),
    ...mapActions({
      calcProRatePricing: "invoiceStore/CALC_PRO_RATE_PRICING_V2",
    }),
    commitStore(): void {
      this.setTabDetailSource(this.dtSource);
    },
    onSelectProduct({ meta, record, col }): void {
      record.productId = meta.meta?.id || "";
      record.brand = meta.meta?.brand || "";
      record[col] = col === "productCode" ? meta.meta?.code || undefined : meta.meta?.name || undefined;
      this.commitStore();
      this.getProductDetail(record);
    },
    async getProductDetail(record): Promise<void> {
      try {
        if (!record.productId) return;
        const { baseUnit, baseUnitId, salesAccountId, salesAccountName } = await productService.detailProduct(record.productId);
        record.uom = baseUnit;
        record.uomId = baseUnitId;
        record.revenueAccount = salesAccountName;
        record.revenueAccountId = salesAccountId;
        this.commitStore();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    viewMore(record): void {
      this.modal.show = true;
      this.setTabDetailRow(record);
    },
    deleteRow(record): void {
      const copy = [...this.dtSource];
      this.dtSource = copy.filter(x => x.no !== record.no);
      this.dtSource.forEach((d, i) => {
        d.no = i + 1;
      });
      this.deletedRow.push(record);

      /**
       * TODO: GPW-937
       * match invoice line with DO line id
      */
      const invoiceLine = {...record};
      const doLines = [...this.form.deliveryOrders]; // list DO
      let $doNumber = "";

     /**
      * iteration through selected DO lines
      */
      for (let doLine of doLines) {
        if (doLine.deliveryOrderNumber === invoiceLine.documentReference) {
            $doNumber = doLine.deliveryOrderNumber; // match DO number!
            break;
        }
      }

      /**
       * find generated DO line from DO number
       */
      this.lookupSiblingByDoNumber($doNumber);

      this.setForm({...this.form, deletedInvoiceARLineIds: this.deletedRow.map(x => x.id)});
      this.commitStore();

      this.calcProRatePricing();
    },
    /**
     * find generated DO line from 
     * DO Number
     */
    lookupSiblingByDoNumber($doNumber = ""): void {
      if (!$doNumber) return;
      let $siblings: InvoiceDetailSource[] = [];

      /**
       * filter all line with same DO number
       */
      $siblings = this.dtSource.filter(x => x.documentReference === $doNumber);

      if (!$siblings.length) {
        /**
         * delete selected DO number
         */
        const $deliveryOrderIds = [...this.form.deliveryOrders];
        this.form.deliveryOrders = $deliveryOrderIds.filter(x => x.deliveryOrderNumber !== $doNumber);
      }
    },
    addRow(): void {
      const { dtSource } = this;
      const newRow = initTabDetailsSource();
      newRow.no = dtSource.length + 1;
      this.dtSource = [...dtSource, newRow];
      this.commitStore();
    },
    onChangeTaxCode(meta, record): void {
      record.taxRate = meta.rate || 0;
      
      this.commitStore();
      this.calcProRatePricing();
    },
    onChangePrice(): void {
      this.commitStore();
      this.calcProRatePricing();
    },
    onChangeQty(): void {
      this.commitStore();
      this.calcProRatePricing();
    },
    onChangeDescription(): void {
      this.commitStore();
    },
  },
});
